div[type='radio'] {
  visibility: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1023px) {
  .DateInput {
    width: 120px !important;
  }
}

.Toastify__toast {
  margin-bottom: 0;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
}

.Toastify__toast--success {
  background-color: rgb(56, 161, 105);
}

.Toastify__toast--error {
  background-color: rgb(229, 62, 62);
}

.Toastify__toast--warning {
  background-color: rgb(221, 107, 32);
}

.Toastify__toast--info {
  background-color: rgb(49, 130, 206);
}

.SingleDatePicker {
  width: 100% !important;
}

.SingleDatePicker > div,
.SingleDatePickerInput__withBorder {
  width: 100% !important;
}

.SingleDatePickerInput__withBorder {
  position: relative !important;
  height: 50px !important;
  border-color: #b8c1cb !important;
  border-radius: 6px !important;
}

.SingleDatePickerInput_calendarIcon {
  margin-left: 16px !important;
  padding: 0 !important;
}

.DateInput_input {
  font-weight: 400 !important;
  border-bottom: 0 !important;
}

.DateInput_input__focused {
  border-bottom: 0 !important;
}

.SingleDatePicker_picker {
  z-index: 50 !important;
}

#arriveDate .SingleDatePickerInput__withBorder {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#departDate .SingleDatePickerInput__withBorder {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
}
